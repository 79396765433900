@import "Palette";

.nav {
    &-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        overflow: hidden;
        list-style-type: none;
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: fit-content;
        background: $light-grey;
        cursor: pointer;
        transition: all .5s ease;

        &:hover .section-title {
            background: darken($light-grey, 3);
            transition: all .5s ease;
        }

        &:last-child {
            border-bottom: 1px solid transparent;

            &.active {
                border-bottom: 1px solid $light-grey2;
            }
        }

        &.active {
            background: $white;
            cursor: default;

            span {
                color: $grey;
            }

            .section-content {
                display: flex;
                flex-direction: column;
                opacity: 1;
                height: fit-content;
                padding: 1rem 1rem 1rem 2rem;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            }

            .section-title {
                & span img {
                    transform: rotate(-180deg);
                    transition: transform .5s ease-out;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .section-title {
            width: 100%;
            margin: 0;
            padding: 1rem 1rem 1rem 2rem;
            background: $light-grey;
            color: $grey;

            span {
                margin: 0;
                display: flex;
                justify-content: space-between;
                line-height: 1.3rem;

                & img {
                    width: 1rem;
                    height: 1rem;
                    transform: rotate(-90deg);
                    filter: invert(70%) sepia(87%) saturate(6096%) hue-rotate(330deg) brightness(94%) contrast(94%);
                    transition: transform .5s ease-out;
                }
            }
        }

        .section-content {
            height: 0;
            align-items: center;
            margin: 0;
            padding: 0;
            opacity: 0;
            transition: opacity .5s .1s ease-out;
            overflow-x: hidden;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            max-height: 17rem;

            &::-webkit-scrollbar {
                width: .3rem;
            }

            &::-webkit-scrollbar-track {
                width: .3rem;
                background-color: $light-grey;
            }

            &::-webkit-scrollbar-thumb {
                background-color: darken($light-grey, 10);
            }
        }
    }
}
